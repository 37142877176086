.cart-page {

}

.cart-item {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px lightblue;

    .cart-item__header {
        img {
            width: 80px;
            height: 50px;
        }
    }

    .cart-item__content {
        font-family: Consolas;
    }
}
