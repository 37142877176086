.container {
    max-width: 370px !important;
    padding: 0;
    margin: auto;
}


@media screen and (max-device-width: 500px) {
    .container {
        max-width: unset !important;
        width: 100%;
        padding: 0;
    }
}

#root {
    background-color: lightblue;
    color: black;
}

.border-radius-none {
    border-radius: unset;
}

.number-circle {
    font-size: 15px;
    font-weight: bold;
    color: red;
    width: 30px;
    height: 30px;
    border: solid 1px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    font-family: Consolas;
    overflow: hidden;
}

.number-circle-2 {
    width: 60px;
    border-radius: 20px;
}
.number-circle-3 {
    width: 45px;
    border-radius: 20px;
}
.number-circle-sm {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 18px;
}

.link-unset {
    color: unset;
    text-decoration: unset;
    &:hover {
        color: unset;
        text-decoration: unset;
    }
}

.overflow-50x {
    max-height: 50vh;
    overflow: auto;
}

.input-readyOnly-reset[readonly] {
    background-color: white;
}

.bootstrap-reset {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
        padding: 0;
    }
    .row {
        margin: 0;
    }
}

.list-item {
    min-height: 70px;
    &:hover {
        cursor: pointer;
    }
    .left-component {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 35px;
            height: 35px;
        }
    }
    .content-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 55%;
    }
    .right-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;
    }
}

.notification-item {
    min-height: 70px;
    &:hover {
        cursor: pointer;
    }
    .left-component {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 40px;
            height: 40px;
        }
    }
    .content-component{
        width: 80%;
        padding-right: 5px;
    }
}

::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #a5b3c9;
}

.smalltalk {
    z-index: 10000 !important;
    .page {
        max-width: 500px;
    }
}

.hover-pointer:hover {
    cursor: pointer;
}

.hover-animation-scale:hover {
    transform: scale(1.2);

}

.form-control {
    height: calc(2.5rem + 2px);
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.modal-selector {
    .modal-content {
        background-color: unset;
    }
}

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
}

//.background-logo {
//    background-image: url("/img/logo/logo-background.png");
//    background-size: 50% ;
//    background-position-x: 50%;
//    background-position-y: 250px;
//    background-repeat: no-repeat;
//}
