.wrapper{
  align-items: stretch;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("/img/background/background.jpg");
  background-size: 100% 100%;
}

.wrapper-boxed {
  max-width: 1440px;
  margin: 0 auto;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
