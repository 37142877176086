.result-keno-card {
    .keno-2 {
        font-size: 0.875rem;
        span {
            border: solid 1px lightblue;
            width: 40%;
            padding-top: 3px;
            padding-bottom: 3px;
            display: inline-block;
        }

        .left-container {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            margin-right: 1px;
        }

        .right-container {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            margin-left: 1px;
        }
    }
}
.result-dien-toan{
    .table td{
        border: thin solid #ccc;
        padding: 5px;
        color:#000000;
    }
   
}
.side{
    width: 25%;
}
.res-dt-special{
    font-weight: bold;
    color:#ff1a1a !important;

}