footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: $white;
  border-top: 1px solid $gray-300;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
