.promotion-page {
    .lucky-circle {
        width: 600px;
        height: 600px;
        position: relative;
        .lucky-circle__circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
        }
        .lucky-circle__pointer, .lucky-circle__border {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;

        }
        .lucky-circle__pointer {
            img {
                width: 150px;
            }
        }

    }
}
