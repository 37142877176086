.sidebar2 {
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    transition: $sidebar-transition;
    background: $sidebar-bg;
    border-right: $sidebar-border;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99998;
    display: flex;
    flex-direction: column;


    .btn-close-sidebar {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99999;
        padding: 10px;
    }

    .user-information {
        color: white;
        .financial {
            div:first-child {
                div:first-child {
                    color: yellow;
                }
            }
            div:last-child {
                div {
                    span {
                        border: solid 1px white;
                        border-radius: 10px;
                        width: 50px;
                        display: inline-block;
                        text-align: center;
                    }
                }
            }
        }
    }

    .sidebar-content {
        overflow-y: auto;
        height: 100%;
        background-color: $sidebar-bg;
        .sidebar-item {
            padding: 10px;
            color: red;
            font-size: 1rem;
            font-weight: bold;
            svg,
            .feather {
                color: red;
                height: 20px;
                width: 20px;
            }
        }
        .sidebar-item.active {
            background-color: #f5f5f5;
        }
    }

}

.sidebar2.show {
    -webkit-box-shadow: 10px 0px 40px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 10px 0px 40px 0px rgba(0,0,0,0.4);
    box-shadow: 10px 0px 40px 0px rgba(0,0,0,0.4);
}

.sidebar2.hide {
    margin-left: -$sidebar-width;
}
